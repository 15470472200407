import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123')
];

export const server_loads = [0];

export const dictionary = {
		"/__flush": [17],
		"/app": [18,[2]],
		"/app/account/[id]": [25,[2,4]],
		"/app/account/[id]/article": [26,[2,4]],
		"/app/account/[id]/contest": [27,[2,4]],
		"/app/account/[id]/edit": [28,[2]],
		"/app/account/[id]/jindo": [29,[2,4]],
		"/app/account/[id]/problemset": [30,[2,4]],
		"/app/account/[id]/question": [31,[2,4]],
		"/app/account/[id]/rival": [32,[2,4]],
		"/app/account/[id]/submission": [33,[2,4]],
		"/app/account/[id]/vote": [34,[2,4]],
		"/app/account/[id]/vs": [35,[2,4]],
		"/app/admin": [36,[2,5]],
		"/app/admin/board": [37,[2,5]],
		"/app/admin/display": [38,[2,5]],
		"/app/admin/faq": [39,[2,5]],
		"/app/admin/user": [40,[2,5]],
		"/app/board": [41,[2]],
		"/app/board/faq": [46,[2]],
		"/app/board/[boardId]": [42,[2]],
		"/app/board/[boardId]/write": [45,[2]],
		"/app/board/[boardId]/[articleId]": [43,[2]],
		"/app/board/[boardId]/[articleId]/edit": [44,[2]],
		"/app/compare/[a]": [47,[2]],
		"/app/compare/[a]/[b]": [48,[2]],
		"/app/contest/[[menu=group]]/my": [50,[2]],
		"/app/contest/rank": [63,[2]],
		"/app/contest/[[menu=group]]": [49,[2]],
		"/app/contest/[contestId]": [51,[2,6]],
		"/app/contest/[contestId]/board/[boardId]": [53,[2,6]],
		"/app/contest/[contestId]/board/[boardId]/write": [55,[2,6]],
		"/app/contest/[contestId]/board/[boardId]/[articleId]": [54,[2,6]],
		"/app/contest/[contestId]/edit": [56,[2,6]],
		"/app/contest/[contestId]/problem/[problemId]": [57,[2,6,7]],
		"/app/contest/[contestId]/problem/[problemId]/solution": [58,[2,6,7]],
		"/app/contest/[contestId]/scoreboard": [59,[2,6]],
		"/app/contest/[contestId]/scoreboard/[account]": [60,[2,6]],
		"/app/contest/[contestId]/submission": [61,[2,6]],
		"/app/contest/[contestId]/submission/[submissionId]": [62,[2,6]],
		"/app/contest/[contestId]/[...notfound]": [52,[2,6]],
		"/app/group/[[menu=group]]": [64,[2]],
		"/app/group/[groupId]": [65,[2,8]],
		"/app/group/[groupId]/board": [67,[2,8,9]],
		"/app/group/[groupId]/board/[boardId]": [68,[2,8,9]],
		"/app/group/[groupId]/contest": [69,[2,8]],
		"/app/group/[groupId]/edit": [70,[2,8]],
		"/app/group/[groupId]/join": [71,[2,8]],
		"/app/group/[groupId]/problemset": [72,[2,8]],
		"/app/group/[groupId]/rank": [73,[2,8]],
		"/app/group/[groupId]/submission": [74,[2,8]],
		"/app/group/[groupId]/[...notfound]": [66,[2,8]],
		"/app/notifications": [75,[2]],
		"/app/(about)/opensource": [19,[2]],
		"/app/(about)/privacy": [20,[2]],
		"/app/problemset": [91,[2]],
		"/app/problemset/my": [94,[2]],
		"/app/problemset/[setId]": [92,[2]],
		"/app/problemset/[setId]/edit": [93,[2,12]],
		"/app/problem": [76,[2]],
		"/app/problem/create": [87,[2]],
		"/app/problem/new": [88,[2]],
		"/app/problem/recent": [89,[2]],
		"/app/problem/tag/[tag]": [90,[2]],
		"/app/problem/[problemId]/(view)": [77,[2,10,11]],
		"/app/problem/[problemId]/(view)/answer": [78,[2,10,11]],
		"/app/problem/[problemId]/data": [85,[2,10]],
		"/app/problem/[problemId]/(view)/discuss": [79,[2,10,11]],
		"/app/problem/[problemId]/edit": [86,[2,10]],
		"/app/problem/[problemId]/(view)/hint": [80,[2,10,11]],
		"/app/problem/[problemId]/(view)/qna": [81,[2,10,11]],
		"/app/problem/[problemId]/(view)/ranking": [82,[2,10,11]],
		"/app/problem/[problemId]/(view)/submission": [83,[2,10,11]],
		"/app/problem/[problemId]/(view)/vote": [84,[2,10,11]],
		"/app/(about)/provision": [21,[2]],
		"/app/ranking/[...method]": [95,[2,13]],
		"/app/sandbox": [96,[2,14]],
		"/app/sandbox/[id]": [97,[2,14]],
		"/app/(about)/sitemap.xml": [22,[2]],
		"/app/submission": [98,[2]],
		"/app/submission/[submissionId]": [99,[2]],
		"/app/upload/[bucket]/[...path]": [100,[2]],
		"/app/(about)/whatsnew": [23,[2]],
		"/app/[...notfound]": [~24,[2],[,3]],
		"/auth/challenge": [101,[15]],
		"/auth/findId": [102,[15]],
		"/auth/findId/done": [103,[15]],
		"/auth/mfa": [104,[15,16]],
		"/auth/mfa/challenge": [105,[15,16]],
		"/auth/mfa/done": [106,[15,16]],
		"/auth/mfa/generate": [107,[15,16]],
		"/auth/mfa/register": [108,[15,16]],
		"/auth/oauth/google": [109,[15]],
		"/auth/oauth/naver": [110,[15]],
		"/auth/passkey": [111,[15]],
		"/auth/resetPassword/done": [112,[15]],
		"/auth/resetPassword/invalid": [113,[15]],
		"/auth/resetPassword/request": [114,[15]],
		"/auth/resetPassword/success": [115,[15]],
		"/auth/resetPassword/verify": [116,[15]],
		"/auth/signin": [117,[15]],
		"/auth/signup": [118,[15]],
		"/auth/signup/handle": [119,[15]],
		"/auth/signup/info": [120,[15]],
		"/auth/signup/mail": [121,[15]],
		"/auth/signup/password": [122,[15]],
		"/www/[...path]": [123]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';